var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ek-dialog',{ref:"dialog",attrs:{"btnText":"مستخدم جديد","isEdit":!!_vm.dtoDetailsDashUser.id,"title":_vm.dtoDetailsDashUser.id ? 'تعديل مستخدم لوحة' : 'اضافة مستخدم لوحة',"placeholder":"ابحث عن مستخدم محدد"},on:{"delete":function($event){return _vm.deleteDashUser(_vm.dtoDetailsDashUser.id)},"ok":_vm.submitForm,"close":_vm.resetForm,"search":_vm.setSearch},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('validationObserver',{ref:"dashUserForm"},[_c('ek-input-text',{attrs:{"name":"اسم ","placeholder":"ادخل اسم المستخدم","label":"اسم المستخدم","rules":[{ type: 'required', message: 'اسم المستخدم مطلوب' }]},model:{value:(_vm.dtoDetailsDashUser.userName),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "userName", $$v)},expression:"dtoDetailsDashUser.userName"}}),_c('ek-input-text',{attrs:{"name":"nname ","placeholder":"ادخل  الاسم الكامل","label":"الاسم الكامل ","rules":[{ type: 'required', message: ' الاسم الكامل مطلوب' }]},model:{value:(_vm.dtoDetailsDashUser.name),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "name", $$v)},expression:"dtoDetailsDashUser.name"}}),_c('ek-input-text',{attrs:{"rules":[
            { type: 'required', message: 'البريد الإلكتروني مطلوب' },
            {
              type: 'email',
              message: 'يرجى ادخال بريد الكتروني صحيح',
            } ],"placeholder":"ادخل البريد الإلكتروني","name":" email","label":"البريد الالكتروني"},model:{value:(_vm.dtoDetailsDashUser.email),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "email", $$v)},expression:"dtoDetailsDashUser.email"}}),_c('ek-input-text',{attrs:{"placeholder":"********","name":"كلمة السر","rules":[
                          { type: 'required', message: 'كلمة المرور مطلوبة' },
                          {
                              type: 'min:8',
                              message:
                                  'كلمة المرور يجب ان تحوي 8 محارف على الاقل',
                          } ]},model:{value:(_vm.dtoDetailsDashUser.password),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "password", $$v)},expression:"dtoDetailsDashUser.password"}}),_c('ek-input-text',{attrs:{"rules":[
            { type: 'required', message: 'رقم الموبايل مطلوب' },
            { type: 'mobile', message: 'رقم الهاتف غير صالح' } ],"placeholder":"ادخل رقم الموبايل","name":"رقم الهاتف","label":"رقم الموبايل"},model:{value:(_vm.dtoDetailsDashUser.phoneNumber),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "phoneNumber", $$v)},expression:"dtoDetailsDashUser.phoneNumber"}}),_c('ek-input-select',{attrs:{"options":_vm.dashUserRole,"name":" الصلاحيات","label":" الصلاحيات"},model:{value:(_vm.dtoDetailsDashUser.role),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "role", $$v)},expression:"dtoDetailsDashUser.role"}}),(_vm.dtoDetailsDashUser.role == 'DataEntry')?_c('ek-input-select',{attrs:{"options":_vm.subjects.subjects,"multiple":"","name":" المواد","label":" المواد"},model:{value:(_vm.dtoDetailsDashUser.subjectIds),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "subjectIds", $$v)},expression:"dtoDetailsDashUser.subjectIds"}}):_vm._e(),_c('ek-input-text',{attrs:{"name":"العنوان ","placeholder":"ادخل العنوان ","label":"العنوان ","rules":[{ type: 'required', message: 'العنوان  مطلوب' }]},model:{value:(_vm.dtoDetailsDashUser.address),callback:function ($$v) {_vm.$set(_vm.dtoDetailsDashUser, "address", $$v)},expression:"dtoDetailsDashUser.address"}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }